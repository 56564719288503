$(document).ready(function () {

    $('.fitness-banner').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        dots: false,
        arrows: true,
        prevArrow:'<button type="button" class="prev-slide"><i class="icofont-swoosh-left"></i></button>',
        nextArrow:'<button type="button" class="next-slide"><i class="icofont-swoosh-right"></i></button>',
        fade: true,
        responsive: [

            {
                breakpoint: 1200,
                settings: {
                    infinite: true,
                    dots: true ,
                    arrows: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    dots: true ,
                    arrows: false
                }
            },
            {
                breakpoint:600,
                settings: {
                    dots: true ,
                    arrows: false,
                    autoplay: true,
                }
            }
        ]
    });


    $('.featured_items').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        prevArrow:'<button type="button" class="prev-slide"><i class="icofont-swoosh-left"></i></button>',
        nextArrow:'<button type="button" class="next-slide"><i class="icofont-swoosh-right"></i></button>',
        dots: true,
        arrows: true,
        responsive: [

            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true ,
                    arrows: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true ,
                    arrows: false
                }
            },
            {
                breakpoint:600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true ,
                    arrows: false,
                    autoplay: true,
                }
            }
        ]
    });


    $('.dealoftheday').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        prevArrow:'<button type="button" class="prev-slide"><i class="icofont-thin-left"></i></button>',
        nextArrow:'<button type="button" class="next-slide"><i class="icofont-thin-right"></i></button>',
        dots: false,
        arrows: true,

    });


    $('.distributor').slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        dots: true,
        arrows: true,
        responsive: [

            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true ,
                    arrows: false
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: true ,
                    arrows: false
                }
            },
            {
                breakpoint:600,
                settings: {
                    slidesToShow:1,
                    slidesToScroll: 1,
                    dots: true ,
                    arrows: false,
                    autoplay: true,
                }
            }
        ]
    });



    $('.main-menu-toggle').click(function() {

        $(this).parent('.main-navigation').children('.main-menu-container-collapse').first().slideToggle('1000');
    });
    /* **


     * Sub Menu
     **/
    $('nav .menu-item-has-children').append('<span class="sub-toggle"> <i class="fa fa-plus"></i> </span>');
    $('nav .page_item_has_children').append('<span class="sub-toggle-children"> <i class="fa fa-plus"></i> </span>');

    $('nav .sub-toggle').click(function() {
        $(this).parent('.menu-item-has-children').children('ul.sub-menu').first().slideToggle('1000');
        $(this).children('.fa-plus').first().toggleClass('fa-minus');
    });

    $('.navbar .sub-toggle-children').click(function() {
        $(this).parent('.page_item_has_children').children('ul.sub-menu').first().slideToggle('1000');
        $(this).children('.fa-plus').first().toggleClass('fa-minus');
    });


    /*TAB*/
    $('ul.tabs li').click(function () {
        var tab_id = $(this).attr('data-tab');

        $('ul.tabs li').removeClass('current');
        $('.tab-content').removeClass('current');

        $(this).addClass('current');
        $("#" + tab_id).addClass('current');
    })


    // Product Zoom Effect
    $("#zoom").elevateZoom({
        gallery:'album',
        cursor: 'pointer',
        zoomType: "inner",
        zoomWindowFadeIn: 200,
        zoomWindowFadeOut: 250,
        galleryActiveClass: 'active',
        imageCrossfade: true,
        zoomType: "lens",
        lensShape: "rectangle",
        lensSize: 300,
    });


    //counter
    $('.counter').counterUp();




    /* **
     * Category Menu
     **/
    $('.hitStore_main_category_nav .hitStore_main_category_title h3').append('<span class="cat-toggle"> <i class="fa fa-arrow-down"></i> </span>');
    $('.hitStore_main_category_nav .cat-toggle').click(function() {

        $(this).parent().parent().parent('.hitStore_main_category_nav').children('.menu-product-category-container').first().slideToggle('1000');
        $(this).children('.fa-arrow-down').first().toggleClass('fa-arrow-up');
    });

    $('.hitStore_main_category_nav .menu-item-has-children').append('<span class="sub-toggle"> <i class="fa fa-plus"></i> </span>');
    $('.hitStore_main_category_nav .page_item_has_children').append('<span class="sub-toggle-children"> <i class="fa fa-plus"></i> </span>');

    $('.hitStore_main_category_nav .sub-toggle').click(function() {
        $(this).parent('.menu-item-has-children').children('ul.sub-menu').first().slideToggle('1000');
        $(this).children('.fa-plus').first().toggleClass('fa-minus');
    });

    $('.navbar .sub-toggle-children').click(function() {
        $(this).parent('.page_item_has_children').children('ul.sub-menu').first().slideToggle('1000');
        $(this).children('.fa-plus').first().toggleClass('fa-minus');
    });

    /* **
     * Category Menu of category page
     **/
    $('.verticalcat-menu .menu-item-has-children').append('<span class="sub-toggle"> <i class="fa fa-plus"></i> </span>');
    $('.verticalcat-menu .page_item_has_children').append('<span class="sub-toggle-children"> <i class="fa fa-plus"></i> </span>');

    $('.verticalcat-menu .sub-toggle').click(function() {
        $(this).parent('.menu-item-has-children').children('ul.sub-menu').first().slideToggle('1000');
        $(this).children('.fa-plus').first().toggleClass('fa-minus');
    });

    $('.verticalcat-menu .sub-toggle-children').click(function() {
        $(this).parent('.page_item_has_children').children('ul.sub-menu').first().slideToggle('1000');
        $(this).children('.fa-plus').first().toggleClass('fa-minus');
    });
/*-------------------------------------------------*/
    $('#loginModal').on('shown.bs.modal', function () {
        $('#myInput').trigger('focus')
    })
});